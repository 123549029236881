.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 2rem;
}

.footer-title {
  margin: 1rem 0;
}
