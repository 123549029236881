.navbar-container {
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
}

.navbar-container li {
  list-style: none;
  margin: 1.5rem 0;
}

.navbar-container li a {
  text-decoration: none;
  color: white;
  font-size: 1.4rem;
  transition: 0.5s all ease;
  padding: 5px;
  border-radius: 5px;
}

.menu-link {
  text-decoration: none;
  color: white;
  font-size: 1.4rem;
}

.menu {
  display: flex;
  align-items: center;
  transition: 0.5s all ease;
}

.menu:hover {
  background: #c32222;
}

.navbar-container li a i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  outline: none;
}

.navbar-container li a:hover {
  background: #c32222;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  bottom: 0;
  width: 30%;
  height: 100%;
  background-color: #252525;
  z-index: 1;
  padding: 2rem 0;
  transition: 0.75s;
}

.sidebar-active {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 30%;
  height: 100%;
  background-color: #252525;
  z-index: 2;
  padding: 2rem 0;
  transition: 0.75s;
}

.sidebar-container {
  margin: 0 auto;
  width: 80%;
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #111;
  border-radius: 5px;
  padding: 1rem;
}

.sidebar-items {
  list-style: none;
  padding: 1rem;
}

.sidebar-link {
  text-decoration: none;
  font-size: 1.4rem;
  color: white;
  transition: 0.5s;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.sidebar-link:hover {
  color: #c32222;
}

.back-icons {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #ddd;
  cursor: pointer;
}

@media screen and (max-width: 968px) {
  .navbar-list {
    display: none;
  }
  .sidebar-active {
    width: 60%;
  }
}

@media screen and (max-width: 730px) {
  .navbar-list {
    display: none;
  }
  .sidebar-active {
    width: 80%;
  }
}

@media screen and (max-width: 468px) {
  .sidebar-active {
    width: 100%;
  }
}
