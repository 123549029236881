.audio-play-container {
  transition: 0.75s;
}

.audio-play {
  position: fixed;
  bottom: 10%;
  right: 10%;
  z-index: 1;
  padding: 0.9rem;
  border-radius: 10px;
  background: linear-gradient(180deg, #ff6b00 0%, #ffa800 100%);
  width: 120px;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  transition: 0.75s;
}

.audio-play-title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}

.audio-icons {
  background-color: #222;
  font-size: 20px;
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 50%;
}

.audio-play audio {
  width: 20px;
  transition: 0.75s all ease;
  display: none;
}

.audio-play-container:hover * {
  width: 300px;
}

.audio-play:hover * {
  display: inline;
}

.audio-play:hover .audio-icons {
  display: none;
}
