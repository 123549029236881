/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background: #252525;
}

.container {
  margin: 0 auto;
  width: 80%;
}

.pages-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}

.bg-primary-1 {
  background-color: #252525;
}

.bg-primary-2 {
  background-color: #111;
}

.hidden {
  display: none;
}

* {
  --animate-duration: 1000ms !important;
}
