.live-stream-container {
  width: 80%;
  margin: 0 auto 1.5rem auto;
  height: 55vh;
  /* background: rgb(0, 0, 0); */
  border-radius: 5px;
  margin-top: 1rem;
  display: grid;
  place-items: center;
  padding: 1rem;
  position: relative;
}

.live-stream-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.live-stream-items {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
  align-items: center;
  position: relative;
  border-radius: 10px;
}

.live-stream-items audio {
  width: 90%;
  padding: 4px;
  border-radius: 30px;
  position: contain;
  top: 70%;
  z-index: -1;
}

.live-logo-items img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}

.live-stream-blur {
  background: linear-gradient(to bottom, rgb(81, 81, 81), #000);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.7;
}
.live-logo-blur {
  /* background: linear-gradient(to bottom, #fff, #000); */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  opacity: 0.4;
}

@media screen and (max-width: 968px) {
  .live-stream-items {
    position: absolute;
    display: contents;
    width: 250px;
    height: 250px;
    top: 200px;
  }
}

@media screen and (max-width: 720px) {
  .live-stream-items {
    position: fixed;
  }
}


@media screen and (max-width: 468px) {
  .live-stream-items {
    /* position: fixed; */
    /* display: contents; */
    position: absolute;
    display: contents;
    width: 250px;
    height: 250px;
    margin-inline-start: 100px;
  }

}
