.ColorTittle h1{
   color: aqua;
   text-align: center;
   margin-top: 20px;
   margin-bottom: 20px;
}

/* .ImageCrew .ImageGalery{
   justify-content: center;
   text-align: center;
} */