.jumbotron-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 468px) {
  .jumbotron-image{
    object-fit: contain;
  }
}
