/* .profile-container {
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #3498db;
  }
  
  .profile-name {
    margin: 10px 0;
    font-size: 24px;
    color: #333;
  }
  
  .profile-description {
    font-size: 16px;
    color: #777;
  }
   */


   .profile-container {
    text-align: center;
    background-color: #f5f5f5;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .profile-header {
    margin-bottom: 20px;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid #3498db;
  }
  
  .profile-name {
    font-size: 36px;
    margin: 10px 0;
    color: #333;
  }
  
  .profile-title {
    font-size: 18px;
    color: #777;
  }
  
  .profile-details {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .profile-info {
    flex: 1;
    text-align: left;
    margin-right: 10px;
  }
  
  .profile-bio {
    flex: 2;
    text-align: left;
  }
  
  .profile-info p,
  .profile-bio p {
    margin: 8px 0;
    font-size: 16px;
    color: #555;
  }
  